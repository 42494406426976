import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";

export const showToastSuccess = (message, action) => {
  const success = toast.custom(
    <>
      <div
        style={{
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
        className="max-w-full w-[800px] max-h-auto h-auto border-l-8 border-[#02A548] rounded-[14px] p-4 bg-white mt-10"
      >
        <div className="flex flex-col">
          <div className="flex justify-between items-center">
            <span className="text-black14 text-[16px] font-bold">
              {message}
            </span>
            <button
              onClick={() => toast.dismiss(success)}
              type="button"
              className="bg-transparent flex items-center"
            >
              <MaterialIcon color="#141414" icon="close" />
            </button>
          </div>
          <div className="mt-3 flex items-center justify-center">
            <button
              onClick={() => action()}
              className="bg-white rounded-[35px] text-blueDark hover:border-2 hover:border-blueDark w-[170px] p-2 my-3 m l-5"
              type="submit"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </>,
    {
      id: "success",
    }
  );
};

export const showToastError = (message, action) => {
  const error = toast.custom(
    <>
      <div
        style={{
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
        className="max-w-full w-[800px] max-h-auto h-auto border-l-8 border-[#C8001B] rounded-[14px] p-2 bg-white mt-10"
      >
        <div className="flex flex-col">
          <div className="flex justify-between items-center">
            <span className="text-black14 text-[16px] font-bold">
              {message}
            </span>
            <button
              onClick={() => toast.dismiss(error)}
              type="button"
              className="bg-transparent flex items-center"
            >
              <MaterialIcon color="#141414" icon="close" />
            </button>
          </div>
          <div className="mt-7 flex items-center justify-center">
            <button
              onClick={() => action()}
              type="button"
              className="bg-blueDark rounded-[35px] text-white w-[200px] p-2"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>,
    {
      id: "error",
    }
  );
};

export const formatPath = (path) => {
  if (!path) return "";
  return path?.substring(path.lastIndexOf("/") + 1);
};

export const dateForHumans = (timestamp) => {
  if (!timestamp) return;
  let date = new Date(timestamp);

  let day = date.getDate();
  let month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(date);
  let year = date.getFullYear();

  let dayString = day + getDaySuffix(day);

  return `${dayString} ${month} ${year}`;
};

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
const getFileType = (file) => {
  return file?.substring(file?.lastIndexOf(".") + 1);
};

export const previewPaper = (doc) => {
  console.log("doc",doc)
  let link = formatImgPath(doc);
  if (
    getFileType(doc) == "docx" ||
    getFileType(doc) == "doc" ||
    getFileType(doc) == "pdf"
  ) {
    window.open(`${link}`, "_blank");
  } else {
    window.open(link, "_blank");
  }
};

export const formatImgPath = (path) => {
  if (
    path === null ||
    path === "" ||
    path === undefined ||
    typeof path === "object"
  ) {
    return path;
  }

  if (!path.startsWith("./")) {
    return path;
  }
  const host = window.location.host;
  const protocol = window.location.protocol;
  const domain = "kazi254.ke";

  if (host === "localhost:3000") {
    return `${protocol}//${domain}${path.replace("./", "/")}`;
  } else if (host === "localhost:3001") {
    return `${protocol}//${domain}${path.replace("./", "/")}`;
  } else {
    return `https://${domain}${path.replace("./", "/")}`;
  }
};

export const printFile = (file) => {
  const { projaFile } = file;
  const printWindow = window.open(formatImgPath(projaFile ?? null), "_blank");
  printWindow.addEventListener("load", () => {
    printWindow.print();
  });
};

export const downloadFile = (file) => {
  console.log(formatImgPath(file));
  fetch(
    formatImgPath(file, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        "Access-Control-Allow-Origin": "*", // Set the appropriate origin or '*' for any origin
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS", // Add additional methods if needed
        "Access-Control-Allow-Headers": "Content-Type, Authorization", // Add additional headers if needed
      },
    })
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("File download failed.");
      }
      return response.blob();
    })
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = file;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

export const capitalizeFirstLetter = (str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const numberWithCommas = (money) => {
  return Number(money)
    .toLocaleString({
      style: "currency",
      currency: "KES",
    })
    .toLowerCase();
};

export function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

export function cashConverter(money) {
  return capitalize(
    Number(money).toLocaleString("en-US", {
      style: "currency",
      currency: "KES",
    })
  );
}

export const timeAmPm = (timestamp) => {
  if (!timestamp) return;

  let time = new Date(timestamp).toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return time;
};

export const timeAuto = (timestamp) => {
  if (!timestamp) return;

  let time = new Date(timestamp)?.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric", 
    hour12: false
  });
  return time;
};


export const calculateTimeDifference = (startTime, endTime) => {
  const start = new Date(startTime);
  const end = new Date(endTime);

  const timeDifference = end ? Math.floor((end - start) / (1000 * 60)) : 0;
  if (timeDifference) {
    let hours = Math.floor(timeDifference / 60);
    let minutes = timeDifference % 60;
    return `${hours} hours ${minutes} minutes`;
  } else {
    return `0 hours 0 minutes`;
  }
};

export const getTimeFromDate = (dateString) => {
  if (!dateString) return "Invalid Date";

  const date = new Date(dateString);

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  return `${hours}:${minutes}:${seconds}`;
};

export const getFirstLetter = (word) => {
  if (!word || typeof word !== "string") return "Invalid input";

  return word.charAt(0);
};

export const getLetterWord = (sentence) => {
  if (!sentence || typeof sentence !== "string") return "Invalid input";
  const words = sentence.split(" ");
  const firstLetters = words.map((word) => word.charAt(0));

  return firstLetters.join("");
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
};
export const calculateAge = (dateOfBirth) => {
  const dob = new Date(Number(dateOfBirth));
  const today = new Date();

  let age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();

  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
    age--;
  }

  return age;
};

export const calculateYears = (startTimestamp, durationMonths) => {
  const startMillis = startTimestamp * 1000;
  const startDate = new Date(startMillis);

  // Adjust the start date to the 1st of the month
  startDate.setUTCDate(1);

  // Calculate the end date
  const endDate = new Date(startDate);
  endDate.setUTCMonth(endDate.getUTCMonth() + durationMonths);

  // Calculate the last day of the month for the end date
  endDate.setUTCDate(0);

  const endTimestamp = endDate.getTime() / 1000;
  const endD = dateForHumans(endTimestamp);

  return endD;
};

export const removeCommas = (numberString) => {
  if (
    typeof numberString !== "string" ||
    numberString === null ||
    numberString === undefined
  ) {
    return numberString;
  }

  return numberString.replace(/,/g, "");
};

export const disabledDate = current => { 
  return current && current > moment().startOf('day');
};
 
export const disabledPastDate = (current) => {
  return current && current < moment().startOf("day");
};


export const transformText = (input) => {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const convertDurationMilliseconds = (dateString) => {
  const dateObject = new Date(dateString);

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const timeInMilliseconds = (hours * 60 * 60 + minutes * 60) * 1000;
  return timeInMilliseconds;
};

export const convertGapMilliseconds = (dateString) => {
  const dateObject = new Date(dateString);

  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const timeInMilliseconds = (hours * 60 * 60 + minutes * 60) * 1000;
  return timeInMilliseconds;
};
 
export const timeAmPmUtc = (utcTimestamp) => {
  if (!utcTimestamp) return;
 
  const utcDate = new Date(utcTimestamp);
 
  const eatTime = new Date(utcDate.getTime() + (3 * 60 * 60 * 1000)); 
 
  const time = eatTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return time;
};

  
export const transformToPastTense = (label) => {
  switch (label) {
      case "Shortlist":
          return "Shortlisted";
      case "Schedule Interview":
          return "Scheduled Interview";
      case "Interview Results":
          return "Interviewed";
      case "Offer":
          return "Offered";
      case "Hire":
          return "Hired";
      case "Drop":
          return "Dropped";
      default:
          return label;
  }
};

export const getTimeOfDay=()=>{
  var currentTime = new Date();
  var currentHour = currentTime?.getHours();

  if (currentHour < 12) {
      return "morning";
  } else if (currentHour < 18) {
      return "afternoon";
  } else {
      return "night";
  }
}

export const convertToPercentage = (val) => {
	if (val) {
		let perc = val * 100;
		return Math?.round(perc);
	}else{
		return 0
	}
};


export const checkEditPermission = (menuData,backedName) => { 
  const matchingMenu = menuData?.find((menu) => menu?.mnsBackendName === backedName);
  if (matchingMenu && matchingMenu?.mymEdit) {
    return true;
  } else {
    return false;
  }
};

export const checkDeletePermission = (menuData,backedName) => {
  const matchingMenu = menuData?.find((menu) => menu?.mnsBackendName === backedName);
  if (matchingMenu && matchingMenu?.mymDelete) {
    return true;
  } else {
    return false;
  }
};
