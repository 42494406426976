export const GenderData = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];


  export const eduLevelOptions = [
    {
      label: "None",
      value: "None",
    },
    {
      label: "Kindergaterten",
      value: "Kindergaterten",
    },
    {
      label: "Lower primary",
      value: "Lower primary",
    },
    {
      label: "Upper primary",
      value: "Uower primary",
    },
    {
      label: "High School",
      value: "High School",
    },
    {
      label: "College",
      value: "College",
    },
    {
      label: "University",
      value: "University",
    },
  ];

  export const stageData = [
    {
      label: "Application",
      value: "Application",
      color:'#E1E1E1',
      image:'/images/application.png'
    },
    {
      label: "Shortlist",
      value: "Shortlist",
      color:'#CCE2FF',
      image:'/images/shortlist-color.png'
    },
    {
      label: "Schedule Interview",
      value: "Schedule Interview",
      color:'#FFD7A0',
      image:'/images/interview.png'
    },
    {
      label: "Place",
      value: "Place",
      color:'#E5F7E0',
      image:'/images/place.png'
    },
    {
      label: "Drop",
      value: "Drop",
      color:'#C73939',
      image:'/images/drop.png'
    },  
  ];

  export const candidateLevelOptions = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Application",
      value: "Active",
    }, 
    {
      label: "Shortlisted",
      value: "Shortlist",
    },
    {
      label: "Scheduled Interview",
      value: "Interview",
    },
    {
      label: "Interview Results",
      value: "Interview_Result",
    }, 
    {
      label: "Offer",
      value: "Offer",
    }, 
    {
      label: "Passed Interview",
      value: "Pass_Interview",
    }, 
    {
      label: "Pre Departure Training",
      value: "Pre_departure_training",
    }, 
    {
      label: "Passed Pre-departure training",
      value: "Passed_Pre_Departure_Training",
    },
    {
      label: "Attestation",
      value: "Attestation",
    },
    {
      label: "Passport Immigration",
      value: "Passport_Immigration",
    },
    {
      label: "Received By Employer",
      value: "Received_By_Employer",
    },
    {
      label: "Runaway",
      value: "Runaway",
    },
  ];

  export const rolesOptions = [
    {
      label: "Director",
      value: "Director",
    },
    {
      label: "Hr Manager",
      value: "Hr Manager",
    },
    {
      label: "Member",
      value: "Member",
    },
    
  ];

  export const passportData = [
    {
      label: "Valid Passport",
      value: "Yes",
    },
    {
      label: "No Passport",
      value: "No",
    }, 
    {
      label: "Expired Passport",
      value: "Expired",
    }, 
  ];

  export const interviewBlockData = [
    {
      label: "Block Schedule",
      value: "Block",
    },
    {
      label: "Auto Schedule",
      value: "Auto",
    }, 
    {
      label: "Manual Schedule",
      value: "Manual",
    }, 
  ];

  export const activityData = [
    {
      label: "Meeting",
      value: "Meeting",
    },
    {
      label: "Task",
      value: "Task",
    }, 
    {
      label: "Email",
      value: "Email",
    }, 
    {
      label: "Interview",
      value: "Interview",
    }, 
    {
      label: "Call",
      value: "Call",
    }, 

  ];

  export const rolesData = [
    {
      label: "ADMIN",
      value: "ADMIN",
    },
    {
      label: "VIEWER",
      value: "VIEWER",
    }, 
    
  ];