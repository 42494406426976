import { Table, Skeleton, Dropdown, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkEditPermission, dateForHumans, formatImgPath, formatPath } from "../../utils";
import {
  deleteUser,
  disableUser,
  fetchAdminUsers,
  setUserEditId,
} from "../../features/admin/adminSlice";
import UsersAddModal from "./UsersAddModal";
import morevert from "../../assets/morevert.svg";
import emptyContainer from "../../assets/Container.png";
import DeleteRevampedModal from "../../components/DeleteRevampedModal";
import shield from "../../assets/ShieldSlash.svg";
import trash from "../../assets/Trash.svg";
import toast from "react-hot-toast";
import PermissionModal from "./PermissionModal ";

function UsersTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { usersData, usersLoading } = useSelector((state) => state.admin);
  const { menuSidebarData } = useSelector((state) => state.admin);
  const { user } = useSelector((state) => state.auth);

  const [searchText, setSearchText] = useState("");

  const [prodd, setProdd] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isModalOpenPermission, setIsModalOpenPermission] = useState(false);
  const showModalPermission = async () => {
    setIsModalOpenPermission(true);
  };

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isEnable, setIsEnable] = useState(false);

  


  const showModalDelete = async () => {
    setIsModalOpenDelete(true);
    setIsDelete(true);
    setIsDisable(false);
    setIsEnable(false)
  };

  const showModalDisable = async () => {
    setIsModalOpenDelete(true);
    setIsDisable(true);
    setIsDelete(false);
    setIsEnable(false)
  };

  const showModalEnable = async () => {
    setIsModalOpenDelete(true);
    setIsDisable(false);
    setIsDelete(false);
    setIsEnable(true)
  };

  

  const handleDisable = async (data) => {
    const res = await dispatch(
      disableUser({
        myId: prodd?.myId,
        myStatus: "DISABLED",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await dispatch(
        fetchAdminUsers({
          usrInstId: user?.usrInstId,
        })
      );
      await setIsModalOpenDelete(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleDelete = async (data) => {
    const res = await dispatch(
      deleteUser({
        myId: prodd?.myId,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await dispatch(
        fetchAdminUsers({
          usrInstId: user?.usrInstId,
        })
      );
      await setIsModalOpenDelete(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleEnable = async (data) => {
    const res = await dispatch(
      disableUser({
        myId: prodd?.myId,
        myStatus: "ACTIVE",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await dispatch(
        fetchAdminUsers({
          usrInstId: user?.usrInstId,
        })
      );
      await setIsModalOpenDelete(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const contentDelete =`Delete ${prodd?.usrFirstName}'s account?`
  const contentSubDelete =
    "Are you sure you want to delete this account? This action cannot be undone.";
  const contentDisable = `Disable ${prodd?.usrFirstName}'s account?`;
  const contentSubDisable =
    `${prodd?.usrFirstName} will not be able to access their account unless it is enabled by an administrator`;
    const contentEnable = `Enable ${prodd?.usrFirstName}'s account?`;
    const contentSubEnable =
      `${prodd?.usrFirstName} will be able to access their account`;
  
  const handleEdit = () => {
    setIsModalOpen(true);
  };
  const handleAdd = async () => {
    await setProdd("");
    await setIsModalOpen(true);
  };

  const handleView = async () => {
    dispatch(setUserEditId(prodd?.usrId));
    navigate("/users-view");
  };

  async function fetchUsersDetails() {
    const res = await dispatch(
      fetchAdminUsers({
        usrInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchUsersDetails();
  }, []);
  const columns = [
    {
      title: "Name",
      render: (item) => (
        <>
          <div
            className="flex items-center justify-between cursor-pointer"
           
          >
            <div className="flex justify-start items-center">
            <img
                className="w-9 h-9 rounded-full object-cover"
                src={
                  item?.usrIcon
                    ? formatImgPath(item?.usrIcon)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3">
              {item?.usrFirstName} &nbsp; {item?.usrLastName}
              </span>
            </div>
            <button className="p-0 m-0 flex items-end"   onClick={() => setProdd(item)} type="button">
              <Dropdown
                overlayStyle={{
                  width: "250px",
                }}
                trigger={"click"}
                menu={{ items: settingItems }}
                placement="bottom"
              >
                <img src={morevert} alt="morevert" />
              </Dropdown>
            </button>
          </div>
        </>
      ),
    },
    
    {
      title: "Email",
      dataIndex: "usrEmail",
    },
    {
      title: "Phone Number",
      dataIndex: "usrMobileNumber",
    },
    {
      title: "Status",
      dataIndex: "myStatus",
    },
    {
      title: "Created",
      render: (item) => {
        return <div>{dateForHumans(item?.usrCreatedDate)}</div>;
      },
    },
    {
      title: "Role",
      render: (item) => {
        return <div>{item}</div>;
      },
      dataIndex: "myRole",
    },

    {
      title: "Last active",
      render: (item) => {
        return <div>{dateForHumans(item?.usrUpdatedDate)}</div>;
      },
    },
  ];
  const settingItems = [
    {
      key: "0",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleView}
        >
          View
        </div>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleEdit}
        >
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={showModalPermission}
        >
          Permissions
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className={`font-dmSans text-black font-[400] text-[19px] ${prodd?.myStatus === "DISABLED" ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={prodd?.myStatus === "ACTIVE" ? showModalDisable : null}
        >
          Disable
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          className={`font-dmSans text-black font-[400] text-[19px] ${prodd?.myStatus === "ACTIVE" ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={prodd?.myStatus === "DISABLED" ?  showModalEnable : null}
        >
          Enable
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={showModalDelete}
        >
          Delete
        </div>
      ),
    },
  ];
 
  const filteredData = searchText
    ? usersData?.filter((item) =>
        item?.usrFullNames?.toLowerCase().includes(searchText.toLowerCase())
      )
    : usersData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

 
  return (
    <>
      <div className="px-10 ">
        {usersData && usersData?.length > 0 ? (
          <>
            <h3 className="font-[700] text-[24px] text-black1 dash-title mt-[2.5rem]">
              List of Users
            </h3>

            <div className="flex justify-between items-center w-full mt-[1.5rem]">
              <div className="flex items-center w-full ">
                <Input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search user by name or keyword.."
                  className="text-[16px] font-[400] flex-row-reverse"
                  prefix={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                        fill="#333333"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                        fill="#333333"
                      />
                    </svg>
                  }
                />
              </div>
              {checkEditPermission(menuSidebarData, "EMPLOYER_ADMIN") && (
                <button onClick={handleAdd} className="w-[250px] save-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                    fill="#EDF8FF"
                  />
                </svg>
                Add User
              </button>
              )}
              
            </div>

            <div className="mt-[1.5rem]">
              {usersLoading ? (
                <Skeleton />
              ) : (
                <Table
                  rowSelection={true}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                  }}
                  rowKey={(record) => record?.myId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={{
                    x: 1200,
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className="bg-white flex  mt-[3.19rem] flex-col">
            <h3 className="font-[700] text-[24px] text-black1 dash-title ">
              Let’s start by creating users
            </h3>

            <div
              className="mt-[31px] bg-white full h-[471.33px] rounded-[15px] border border-solid
				      border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
              onClick={handleAdd}
            >
              <div>
                <img
                  src={emptyContainer}
                  alt="group-pic"
                  className="object-contain h-[291.33px] w-[292px]"
                />
                <div className="empty-desc flex items-center justify-center">
                  Add your users
                </div>
              </div>

              <div className="mt-[2.63rem]">
                <button
                  className="w-[270px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
					text-white text-[18px] leading-normal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                  >
                    <path
                      d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                      fill="#EDF8FF"
                    />
                  </svg>
                  Add user
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <UsersAddModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        prodd={prodd}
      />

      <DeleteRevampedModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={
          isDisable ? handleDisable : isDelete ? handleDelete : isEnable ? handleEnable : null
        }
        content={isDelete ? contentDelete : isDisable ? contentDisable :isEnable ? contentEnable  : ""}
        contentSub={
          isDelete ? contentSubDelete : isDisable ? contentSubDisable : isEnable ? contentSubEnable : ""
        }
        icon={isDisable ? shield : isDelete ? trash : isEnable ? shield : ""}
        btnTitle={
          isDisable ? "Disable account" : isDelete ? "Delete account" : isEnable ? "Enable account" : ""
        }
        title="Confirmation"
        loading={usersLoading}
      />

      <PermissionModal
        isModalOpen={isModalOpenPermission}
        setIsModalOpen={setIsModalOpenPermission}
        prodd={prodd}
      />
    </>
  );
}

export default UsersTable;
