import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	docLoading: false,
	docs: [],
	docCV: [],
	docData: [],
	activeDoc: {},
};

export const fetchAttachments = createAsyncThunk(
	'docSlice/fetchAttachments',
	async (usrObj) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchAttachments.action?attInstId=${usrObj?.usrId}&attType=JOBSEEKER`,
		);
		return res.data;
	},
);

export const fetchCVAttachments = createAsyncThunk(
	'docSlice/fetchCVAttachments',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchAttachments.action?attInstId=${usrObj?.usrId}&attCvlId=${usrObj?.attCvlId}`,
		);
		return res.data;
	},
);

export const saveAttachment = createAsyncThunk(
	'docSlice/saveAttachment',
	async (docObj) => {
		const res = await axios.post(`${url}/nea/saveAttachment.action`, docObj);
		return res.data;
	},
);

export const docSlice = createSlice({
	name: 'doc',
	initialState,
	reducers: {
		setDocData: (state, action) => {
			state.docData = action.payload;
		},
		setActiveDoc: (state, action) => {
			state.activeDoc = action.payload;
		},
		clearDocData: (state) => {
			state.docData = {};
		},
		clearActiveDoc: (state) => {
			state.activeDoc = {};
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveAttachment.pending, (state) => {
				state.docLoading = true;
			})
			.addCase(saveAttachment.fulfilled, (state) => {
				state.docLoading = false;
			})
			.addCase(saveAttachment.rejected, (state) => {
				state.docLoading = false;
			})

			.addCase(fetchAttachments.pending, (state) => {
				state.docLoading = true;
			})
			.addCase(fetchAttachments.fulfilled, (state, action) => {
				state.docLoading = false;
				if (action?.payload?.success) {
					state.docs = action?.payload?.data?.result;
				} else {
					state.docs = [];
				}
			})
			.addCase(fetchAttachments.rejected, (state) => {
				state.docLoading = false;
			})

			.addCase(fetchCVAttachments.pending, (state) => {
				state.docLoading = true;
			})
			.addCase(fetchCVAttachments.fulfilled, (state, action) => {
				state.docLoading = false;
				if (action?.payload?.success) {
					state.docCV = action?.payload?.data?.result;
				} else {
					state.docCV = [];
				}
			})
			.addCase(fetchCVAttachments.rejected, (state) => {
				state.docLoading = false;
			});
	},
});

export default docSlice.reducer;
export const { setDocData, setActiveDoc, clearActiveDoc, clearDocData } =
	docSlice.actions;
