import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  Spin,
  Select,
  InputNumber,
} from "antd";
import "../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import uplodSvg from "../../assets/uploadPic.png";
import toast from "react-hot-toast";
import { saveFile } from "../../features/application/applicationSlice";
import {
  createAdminUsers,
  fetchAdminUsers,
} from "../../features/admin/adminSlice";
import { formatImgPath } from "../../utils";
import { rolesData } from "../../Gender";

const roleOptions = [
  {
    label: "Recruiter",
    value: 1,
  },
];
const UsersAddModal = ({ isModalOpen, setIsModalOpen, prodd }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { usersLoading } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function closeModal() {
    toast.dismiss();
  }

  const [formData, setFormData] = useState({ ...prodd });

  const [loadingAttach, setLoadingAttach] = useState(false);
  const [profile, setProfile] = useState(
    formData?.usrIcon ? formData?.usrIcon : ""
  );

  useEffect(() => {
    setProfile(formData?.usrIcon ? formData?.usrIcon : "");
  }, [formData?.usrIcon]);

  const handleImageSelect = async (event) => {
    setLoadingAttach(true);
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    const res = await dispatch(saveFile(formData));
    if (res?.payload?.success) {
      setLoadingAttach(false);
      setProfile(res?.payload?.jsonData ?? "");
    } else {
      setLoadingAttach(false);
      toast.error("Please try upload your image again");
    }
  };

  const [data, setdata] = useState({});

  const handleNumberChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      usrMobileNumber: String(e),
    }));
  };

  useEffect(() => {
    setFormData(prodd);
  }, [prodd]);

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };


  function handleSelectChange(value, formName) {
    setFormData((prevData) => ({
      ...prevData,
      [formName]: value,
    }));
  }

  const onFinish = async (data) => { 
      const res = await dispatch(
        createAdminUsers({
          usrIcon: profile,
          usrRoleId: 4,
          usrFirstName: formData?.usrFirstName,
          usrLastName: formData?.usrLastName,
          usrEmail: formData?.usrEmail,
          usrMobileNumber: formData?.usrMobileNumber,
          usrNationalId: formData?.usrNationalId,
          usrInstId: user?.usrInstId,
          usrNeaVerified: true,
          usrId: formData?.usrId,
          usrType:formData?.usrType,
        })
      );
      if (res?.payload?.success) {
        toast.success(res?.payload?.messages?.message);
        dispatch(
          fetchAdminUsers({
            usrInstId: user?.usrInstId,
          })
        );
        form.resetFields();
        await handleOk();
        await navigate('/users-list')
        setProfile(null);
      } else {
        toast.error(res?.payload?.messages?.message);
      } 
  };
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer users activity-header activity-close"
        title="Create user"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className="px-[15px]"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="items-center flex gap-5 mb-[23px]">
            {!profile ? (
              <label className="flex flex-row items-center justify-start w-[20%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageSelect(e)}
                />
                <img
                  loading="lazy"
                  className="z-10 w-[100px] h-[100px] rounded-full"
                  alt="tab1"
                  src={uplodSvg}
                  style={{ cursor: "pointer" }}
                />
              </label>
            ) : loadingAttach ? (
              <Spin className="spin-modal" />
            ) : (
              <label className="flex flex-row items-center justify-start gap-2  w-[20%]">
                <input
                  name="secIcon"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageSelect}
                />
                <div className="rounded-full  overflow-hidden border-4 border-blueDark">
                  <img
                    src={formatImgPath(profile)}
                    alt="director"
                    className="w-28 h-28 cursor-pointer object-cover"
                  />
                </div>
              </label>
            )}
          </div>

            <div className="grid grid-cols-2 gap-x-5">
          <Form.Item
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please add first name",
              },
            ]}
          >
            <Input
              required
              name="usrFirstName"
              onChange={onChange}
              value={formData?.usrFirstName}
              className="input"
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please add last name",
              },
            ]}
          >
            <Input
              required
              name="usrLastName"
              onChange={onChange}
              value={formData?.usrLastName}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Email"
            rules={[
              {
                required: true,
                message: "Please add email address",
              },
              {
                type: "email",
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input
              required
              name="usrEmail"
              onChange={onChange}
              value={formData?.usrEmail}
              type="email"
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Phone No."
            rules={[
              {
                required: true,
                message: "Please add office phone no",
              },
            ]}
          >
            <InputNumber
              required
              name="usrMobileNumber"
              value={formData?.usrMobileNumber}
              maxLength={10}
              minLength={9}
              onChange={handleNumberChange}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="National ID"
            rules={[
              {
                required: true,
                message: "Please add national identification no",
              },
            ]}
          >
            <Input
              required
              name="usrNationalId"
              onChange={onChange}
              value={formData?.usrNationalId}
              type="number"
              maxLength={8}
              minLength={6}
              className="input"
            />
          </Form.Item>

          <Form.Item
              className=""
              label={
                <span>
                  Access level  
                </span>
              }
              rules={[
                {
                  required: true,
                  message: "Please select access level",
                },
              ]}
            >
              <Select
                name="usrType"
                className=""
                allowClear
                style={{
                  width: "100%",
                }}
                onChange={(value) => {
                  handleSelectChange(value, "usrType");
                }}
                value={formData?.usrType}
                options={
                  rolesData?.length > 0 &&
                  rolesData?.map((item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))
                }
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label?.toLocaleLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                onDropdownVisibleChange={() => { 
                }}
              />
            </Form.Item>
            </div>
          <div className="flex justify-between mt-[48px] ">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center mb-[58px]">
              <button
                type="button"
                key="back"
                onClick={handleCancel}
                className="w-[200px] cancel-btn"
              >
                Cancel
              </button>
              <button key="submit" type="submit" className="w-[200px] save-btn">
                {usersLoading ? <Spin /> : "Create"}
              </button>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default UsersAddModal;
