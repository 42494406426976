import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Input, Modal, Select, Spin, message } from "antd";
import "../../clients/demand-modal/demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchJobs,
  saveJob,
  setRefetchKey,
} from "../../../features/jobs/jobSlice";
import { checkEditPermission } from "../../../utils";

const AddJobModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { menuSidebarData } = useSelector((state) => state.admin);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobLoading } = useSelector((state) => state.jobs);

  const [data, setdata] = useState({});

  const onFinish = async (data) => {
    const res = await dispatch(
      saveJob({
        jobName: data?.jobName,
        jobInstId: user?.usrInstId,
        jobInstType: "EMPLOYER",
        jobCreatedBy: user?.usrId,
      })
    );
    if (res?.payload?.success) {
      toast.success(
        "Job name added successfully,proceed to fill the other details"
      );
      await dispatch(
        fetchJobs({
          jobInstId: user?.usrInstId,
          jobCntId: "",
        })
      );
      // navigate("/job-tabs");
      navigate("/job-view");
      form.resetFields();
      handleOk();
      dispatch(setRefetchKey("1"));
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <Modal
        className="mod mod-height"
        title="Add Job"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="text-black1 text-[22px] font-[400] font-dmSans leading-9 mb-[35px]">
          Fill in the following details to add a job vacancy
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-1">
            <Form.Item
              className="job-vacancy-modal"
              name="jobName"
              label="Job Vacancy Name "
              rules={[
                {
                  required: true,
                  message: "Please add job vacancy name ",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>
          </div>

          <div className="flex justify-between mt-[10rem] mb-[40px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <button
                type="button"
                onClick={handleCancel}
                className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
              >
                Cancel
              </button>
              {checkEditPermission(menuSidebarData, "EMPLOYER_ADVERTS") && (
                <button
                  key="submit"
                  type="submit"
                  className="w-[200px] save-btn"
                >
                  {jobLoading ? <Spin /> : "Continue"}
                </button>
              )}
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddJobModal;
